
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props: any) {
    const { id } = toRefs(props);

    const store = useStore();

    const errorActualizarReserva = computed(() => {
      return store.getters["reserva/errorActualizarReserva"];
    }); //obtener el error del proceso de actualizar reserva

    const reserva = computed(() => store.getters["reserva/getterReserva"]); //reserva
    return {
      errorActualizarReserva,
      reserva,
    };
  },
};
