<template>
  <div v-if="errorActualizarReserva">

     <div class="row">
            <div
              class="
                notice
                d-flex
                bg-light-error
                rounded
                border-gray border border-dashed
                mb-9
                p-6
              "
            >
              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-2 text-gray-600">Ups, no es posible crear la reserva en estos momentos, por favor intenta mas tarde.</div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>
   
  </div>

  <div
    v-if="!errorActualizarReserva"
    class="page d-flex flex-row flex-column-fluid"
  >
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <div class="card mb-5 mb-xxl-8 custom-card h-100 px-5">
        <div class="card-body pt-9 pb-0">
          <div class="row">
            <div
              class="
                notice
                d-flex
                bg-light-success
                rounded
                border-gray border border-dashed
                mb-9
                p-6
              "
            >
              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-2 text-success"><b> Que bien, la reserva fue creada correctamente.</b></div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>

          <div class="row">
            <!--begin::Table-->
            <table
              class="
                table table-row-dashed table-row-gray-300
                align-middle
                gs-0
                gy-4
              "
            >
              <!--begin::Table head-->
              <thead>
                <tr class="fw-bolder text-muted fs-4">
                  <th class="min-w-150px">Datos de la reserva</th>
                </tr>
              </thead>
              <!--end::Table head-->

              <!--begin::Table body-->
              <tbody>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-start flex-column">
                        <a class="text-dark fw-bolder text-hover-primary fs-4"
                          >{{ reserva.fechaIngreso }} -
                          {{ reserva.horaIngreso }}</a
                        >

                        <span class="text-muted fw-bold text-muted d-block fs-6"
                          >Fecha y hora (24h)</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-start flex-column">
                        <a class="text-dark fw-bolder text-hover-primary fs-4"
                          >{{ reserva.nombre }} {{ reserva.apellido }}</a
                        >

                        <span class="text-muted fw-bold text-muted d-block fs-6"
                          >Nombre y apellido</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-start flex-column">
                        <a
                          class="text-dark fw-bolder text-hover-primary fs-4"
                          >{{ reserva.descripcionServicio }}</a
                        >

                        <span class="text-muted fw-bold text-muted d-block fs-6"
                          >Servicio adquirido</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>


                  <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-start flex-column">
                        <a
                          class="text-dark fw-bolder text-hover-primary fs-4"
                          >{{ reserva.nombreProfesional }}</a
                        >

                        <span class="text-muted fw-bold text-muted d-block fs-6"
                          >Profesional asignado</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>


                 <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-start flex-column">
                        <a
                          class="text-dark fw-bolder text-hover-primary fs-4"
                          >{{ reserva.nombreSucursal }} {{ reserva.direccionSucursal }} {{ reserva.telefonoSucursal }}</a
                        >

                        <span class="text-muted fw-bold text-muted d-block fs-6"
                          >Sucursal, dirección y teléfono</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end::Table body-->
            </table>
            <!--end::Table-->
          </div>

          <div class="row pt-5">
            <div
              class="
                notice
                d-flex
                bg-light-primary
                rounded
                border-gray border border-dashed
                mb-9
                p-6
              "
            >
              <span class="me-4">
                <img
                  src="media/icons/duotone/Code/Done-circle.png"
                  alt="image"
                />
              </span>

              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-4 text-gray-600">
                    <p>En breve recibiras un email con mas detalles de tu reserva y el código QR para el acceso al establecimiento</p>
                  
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>

          <div class="col-lg-6 fv-row">
            <button @click="guardar" class="btn btn-success btn-lg">
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props: any) {
    const { id } = toRefs(props);

    const store = useStore();

    const errorActualizarReserva = computed(() => {
      return store.getters["reserva/errorActualizarReserva"];
    }); //obtener el error del proceso de actualizar reserva

    const reserva = computed(() => store.getters["reserva/getterReserva"]); //reserva
    return {
      errorActualizarReserva,
      reserva,
    };
  },
};
</script>

<style>
</style>